.navbar {
  background: black;
  padding: 10px 20px;
  display: flex;        /* Continues to use flexbox */
  align-items: center;  /* Centers the logo vertically */
}

.logo {
  margin: 0;
  margin-left: 30px;    /* Adds space from the left edge */
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;     /* Thinner weight */
  font-size: 2em;
}

/* Global styles - typically in App.css or index.css */
body {
  background-color: black;
  color: white; /* Ensures text is readable on a dark background */
  margin: 0;    /* Removes default margin */
  font-family: 'Montserrat', sans-serif; /* Keeps the font consistent */
}

.banner {
  position: relative;
  width: 100%;
  height: 40vh; /* 75% of the viewport height */
  background: url('./hero_image.jpeg') no-repeat center center/cover; /* Adjust with your actual image URL */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin-left: 30px; 
  color: white;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.7); /* Enhances text readability */
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  background-blend-mode: darken; /* Ensures the overlay darkens the background */
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 3em; /* Large text for main heading */
  margin: 0.5em 0; /* Spacing around the heading */
}

h1 {
  font-size: 2em; /* Large text for main heading */
  margin: 0.5em 0; /* Spacing around the heading */
}

.mission {
  font-size: 1.2em; /* Smaller text for subtitle */
  margin: 0.5em 0; /* Spacing around the subtitle */
}


p {
  font-size: 1.2em; /* Smaller text for subtitle */
  margin: 0.5em 0; /* Spacing around the subtitle */
}

.dual-panel {
  display: flex;
  flex-direction: row; /* Default to side-by-side */
  align-items: stretch;
  border: 0;
  position: relative;
  height: auto;
  margin-bottom: 20px; /* Space after the dual-panel */
}


/* SignupForm.css */


.panel {
  flex: 1; /* Each panel takes up half the width */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.divider {
  width: 2px;
  background: linear-gradient(to bottom, transparent, white, transparent);
  height: 100%;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  font-size: 1.2em;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

li svg {
  flex-shrink: 0;
}

.signup-form-container {
  background-color: black;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  margin-top: 10px; /* Ensure there's space between this form and the element above it */
  position: relative;
}

.signup-form {
  display: flex;
  flex-direction: row; /* Default to side by side */
  gap: 10px; /* Adds space between the input and the button */
  width: 100%; /* Ensure form takes full width */
  max-width: 400px; /* Maximum width for the form */
  margin: 0 auto; /* Center the form horizontally */
}

input[type="email"] {
  flex: 1; /* Allows the input field to expand and take up available space */
  padding: 12px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  border-radius: 4px;
  min-width: 0; /* Prevents flex item from growing beyond its container */
}

button {
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for button hover */
}

@media (max-width: 768px) {
  .navbar {
    padding: 5px 10px; /* Smaller padding for mobile */
  }

  .logo {
    margin-left: 10px; /* Smaller margin for mobile */
    font-size: 2em; /* Smaller font size for mobile */
    font-weight: 400; /* Thinner weight for mobile */
  }

  .banner {
    height: 30vh; /* Shorter height on mobile */
    padding: 10px; /* Add padding to avoid text touching the edges */
  }

  .banner h1, .banner .mission, .banner p {
    font-size: 1.5em; /* Smaller font size for headings and text */
    text-align: center; /* Center text for better mobile readability */
  }

  .dual-panel {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .signup-form-container {
    margin-top: 10px; /* Ensures adequate space on smaller screens */
  }

  .divider {
    display: none; /* Hide divider on mobile */
  }

  .signup-form {
    flex-direction: column; /* Stack elements vertically on smaller screens */
  }

  input[type="email"], button {
    width: 100%; /* Full width for better mobile usability */
  }

  .panel {
    padding: 10px; /* Smaller padding in panels for mobile */
  }

  ul {
    padding: 5px; /* Adjust padding in lists */
  }

  li {
    font-size: 1em; /* Smaller font size in list items */
    gap: 5px; /* Smaller gap between icon and text */
  }

  li svg {
    width: 20px; /* Adjust icon size for mobile */
    height: 20px; /* Adjust icon size for mobile */
  }
}
